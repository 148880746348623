<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" /> -->
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
                {
                    items: [{
                        label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
                    }]
                },
                {
                    items: [
                        {
                            label: 'Goaff', icon: 'pi pi-cloud',
                            items: [
                                {
                                    label: 'Coupons',
                                    icon: 'pi pi-fw pi-ticket',
                                    items: [
                                        { label: 'List', icon: 'pi pi-fw pi-list', to: '/coupons' },
                                        { label: 'By months', icon: 'pi pi-fw pi-calendar-times', to: '/coupons/months' },
                                    ]
                                },
                                {
                                    label: 'Affiliates',
                                    icon: 'pi pi-fw pi-users',
                                    items: [
                                        { label: 'List', icon: 'pi pi-fw pi-list', to: '/affiliates' },
                                        { label: 'Balance by date', icon: 'pi pi-fw pi-chart-line', to: '/affiliates/balance' },
                                    ],
                                },
                                { label: 'Orders', icon: 'pi pi-fw pi-book', to: '/orders' },
                            ],
                                
                                
                        },
                        {
                            label: 'Gorgias', icon: 'pi pi-inbox',
                            items: [
                                { label: 'Export reports', icon: 'pi pi-fw pi-calendar', to: '/gorgias/datesfilter' },
                                { label: 'Analisys of tickets', icon: 'pi pi-tag' , to:'/gorgias/analysis'}
                            ]
                        },
                        {
                            label: 'Shopify', icon: 'pi pi-shopping-bag',
                            items: [
                                { label: 'Orders', icon: 'pi pi-fw pi-book', to: '/shopify/vendors'},                           
                                { label: 'Total orders', icon: 'pi pi-shopping-cart', to: '/shopify/orders' }
                            ]
                        },
                        {
                            label: 'Reviews', icon: 'pi pi-comments ',
                            items:[
                                { label: 'Reports', icon: 'pi pi-fw pi-calendar', to: '/reviews/export'},
                                { label: 'Analysis', icon: 'pi pi-comment', to: '/reviews/analysis'},
                                { label: 'Analysis competitors', icon: 'pi pi-list', to: '/reviews/competitor'}
                            ]
                        },
                    ]
                },
                {
                    items: [{
                        label: 'User', icon: 'pi pi-fw pi-user', to: '/create'
                    }]
                },
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        // 'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';

body {
    background-image: linear-gradient(180deg, rgba(251, 251, 251, 0.8379726890756303) 0%, rgba(251, 251, 251, 0.84) 35%, rgba(251, 251, 251, 0.84) 100%), url("../public/images/background.png");
    background-repeat: repeat;

}</style>
