<template>
	<div class="layout-footer">
		<!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" /> -->
		<span class="font-medium ml-2 made">made rigth by</span>
		<img alt="Logo" :src="footerImage()" height="20" class="mr-2" />
		<!-- <span class="font-medium ml-2">PrimeVue</span> -->
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/2becommerce.webp' : 'images/2becommerce.webp';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>
<style>
.made{
	margin-right: 0.5rem;
	color: #000
}
</style>