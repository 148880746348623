<template>
	<Toast />
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span class="title-logo">GG integrator</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars" style="color: #562290;"></i>
		</button>
		
		<!-- <Dropdown class="selector-store" v-model="selectedDropdownValue" :options="dropdownValues"  @change="storeChange" :inputStyle="{height: '2rem', padding: '0 0 0 0.5rem'}"/> -->

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
				v-bind:class="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
				leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden xl:flex origin-top">
			<li class="li-store">
				<Dropdown class="selector-store" v-model="selectedDropdownValue" :options="dropdownValues"  @change="storeChange" :inputStyle="{height: '2.5rem', padding: '0.5rem 0 1rem 0.5rem', outline: 'none'}"/>
			</li>
			<li>
				<div class="user pi pi-user" v-tooltip.top="user"></div>
				<button class="p-link layout-topbar-button" v-tooltip.top="'Log Out'" style="color: #562290; border: 2px solid; border-radius: 6px; width: 2.75rem; height: 2.75rem; margin-left: 0.5rem" @click="logOut">
					<i class="pi pi-sign-out" style=" font-weight: 600;"></i>
				</button>
			</li>
		</ul>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			user : localStorage.getItem('email') ? localStorage.getItem('email') : 'Unknown',
			dropdownValues: [
				'sonryse','snatched','shapes-secret',
				],
			selectedDropdownValue: localStorage.getItem('store'),
		}
	},
	watch: {
		user(newValue) {
			if (newValue === 'Unknown') {
				localStorage.clear();
				this.$router.push('/login');
			}
		}
	},
	mounted() {
		if (this.user === 'Unknown') {
			localStorage.clear();
			this.$router.push('/login');
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/gg-logo.png' : 'images/gg-logo.png';
		},
		logOut() {
			localStorage.clear();
			this.$router.push('/login');
		},
		storeChange(event) {
			//let valorAlmacenado = localStorage.getItem('store');
	
			localStorage.setItem('store', event.value);
			window.location.reload();
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style>
	.title-logo {
		color: #562290;
	}
	.p-link {
		text-align: left;
		background-color: transparent;
		margin: 0;
		padding: 0;
		border: none;
		cursor: pointer;
		user-select: none;
	}
	.user {
		border-radius: 6px;
		font-weight: 600;
		color: #562290;
		font-size: 1.5rem;
		border: 2px solid;
        padding: 6.5px
	}
	.selector-store {
		right: 0;
	}
	.p-dropdown {
        border: 2px solid #562290;
	}
	.li-store {
		margin: 0 8px 0 0;
	}
</style>