import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
		meta: { requiresAuth: true },
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('./pages/Dashboard.vue')
            },
			{
                path: '/orders',
                name: 'orders',
                component: () => import('./pages/Orders.vue')
            },
			{
                path: '/coupons',
                name: 'coupons',
                component: () => import('./pages/Coupons.vue')
            },
			{
                path: '/coupons/months',
                name: 'coupons by months',
                component: () => import('./pages/CouponsByMonths.vue')
            },
            {
                path: '/affiliates',
                name: 'affiliates',
                component: () => import('./pages/Affiliates.vue')
            },
			{
                path: '/affiliates/balance',
                name: 'affiliates balance',
				component: () => import('./pages/AffiliatesBalanceByDate.vue')
            },
            {
                path: '/gorgias/datesfilter',
                name: 'datesf',
                component: () => import('./pages/Filterdates.vue')
            },
            {
                path: '/gorgias/analysis',
                name: 'analysis of tickets',
                component: () => import('./pages/Tallas.vue')
            },
            {
                path: '/shopify/orders',
                name: 'shopiOrder',
                component: () => import('./pages/ShopifyTotalorders.vue')
            },
            {
                path: '/shopify/vendors',
                name: 'shopiVendors',
                component: () => import('./pages/Shopifyorders.vue')
            },
            {
                path: '/reviews/export',
                name: 'Review',
                component: () => import('./pages/Reviews.vue')
            },
            {
                path: '/reviews/analysis',
                name: 'Analysis',
                component: () => import('./pages/AnalysisReviews.vue')
            },
            {
                path: '/reviews/competitor',
                name: 'Analysis competitor',
                component: () => import('./pages/Reviewscompetitor.vue')
            },
			{
				path: '/create',
				name: 'create user',
				component: () => import('./pages/CreateUser.vue')
			}
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('token');
        if (token) {
            return next();
        }
        return next('/login');
    }
	next();
});
export default router;
